import React, { Fragment, useEffect, useMemo, useState, useCallback } from 'react';
import isHotkey from 'is-hotkey';
import isUrl from 'is-url';
import { Slate, Editable, withReact, useSlate } from 'slate-react';
import { Transforms, Editor, Range, createEditor, Node, Text } from 'slate';
import { withHistory } from 'slate-history';
import { jsx } from 'slate-hyperscript';
import escapeHtml from 'escape-html';

import Label from '@components/Label';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ELEMENT_TAGS = {
    A: el => ({ type: 'link', url: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    IMG: el => ({ type: 'image', url: el.getAttribute('src'), alt: el.getAttribute('alt') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' }),
    BR: () => ({ type: 'break' }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
};

export const deserialize = el => {
    if (el.nodeType === 3) {
        return el.textContent;
    } else if (el.nodeType !== 1) {
        return null;
    } else if (el.nodeName === 'BR') {
        return '\n';
    }

    const { nodeName } = el;
    let parent = el;

    if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
        parent = el.childNodes[0];
    }

    const children = Array.from(parent.childNodes)
        .map(deserialize)
        .flat();

    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children);
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el);
        return jsx('element', attrs, children.length > 0 ? children : [{ text: '' }]);
    }

    if (TEXT_TAGS[nodeName]) {
        // console.log('YES', {
        //     nodeName,
        //     children,
        //     find: children.find(child => {
        //         console.log({ child, isText: Text.isText(child) });
        //         return Text.isText(child);
        //     }),
        // });
        // const attrs = TEXT_TAGS[nodeName](el);
        // return children.find(child => Text.isText(child))?.map(child => jsx('text', attrs, child));

        const attrs = TEXT_TAGS[nodeName](el);
        return children.map(child => jsx('text', attrs, child));
    }

    return children.length > 0 ? children : [{ text: '' }];
};

const serialize = node => {
    if (Text.isText(node)) {
        let text = escapeHtml(node.text);

        if (node.bold) {
            text = `<strong>${text}</strong>`;
        }

        if (node.code) {
            text = `<pre>${text}</pre>`;
        }

        if (node.del) {
            text = `<del>${text}</del>`;
        }

        if (node.underline) {
            text = `<u>${text}</u>`;
        }

        if (node.italic) {
            text = `<i>${text}</i>`;
        }

        return text;
    }

    const children = node.children.map(n => serialize(n)).join('');

    switch (node.type) {
        case 'break':
            return `<br />`;
        case 'quote':
            return `<blockquote><p>${children}</p></blockquote>`;
        case 'heading-one':
            return `<h1>${children}</h1>`;
        case 'heading-two':
            return `<h2>${children}</h2>`;
        case 'heading-three':
            return `<h3>${children}</h3>`;
        case 'numbered-list':
            return `<ol>${children}</ol>`;
        case 'bulleted-list':
            return `<ul>${children}</ul>`;
        case 'list-item':
            return `<li>${children}</li>`;
        case 'paragraph':
            return `<p>${children}</p>`;
        case 'img':
            return `<img src="${escapeHtml(node.url)}" alt="${escapeHtml(node.alt)}" />`;
        case 'link':
            return `<a href="${escapeHtml(node.url)}">${children}</a>`;
        default:
            return children;
    }
};

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const HtmlField = ({
    name,
    value: initialValue,
    label,
    optional,
    hint,
    setFieldValue,
    onChange,
    placeholder = 'Enter some plain text...',
}) => {
    const doc = new DOMParser().parseFromString(initialValue || '<p></p>', 'text/html');
    const [value, setValue] = useState(deserialize(doc.body));
    const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))), []);
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);

    const handleChange = value => {
        setValue(value);
        const data = serialize({ children: value });

        if (onChange) {
            onChange(data);
        } else if (setFieldValue && name) {
            setFieldValue(name, data);
        }
    };

    return (
        <Fragment>
            {!!label && <Label text={label} optional={optional} hint={hint} />}
            <Slate editor={editor} value={value} onChange={handleChange}>
                <div className={styles.toolbar}>
                    <MarkButton format="bold" icon="bold" />
                    <MarkButton format="italic" icon="italic" />
                    <MarkButton format="underline" icon="underline" />
                    <MarkButton format="code" icon="code" />
                    <BlockButton format="heading-one" icon="h1" />
                    <BlockButton format="heading-two" icon="h2" />
                    <BlockButton format="heading-three" icon="h3" />
                    <BlockButton format="quote" icon="quote-left" />
                    <BlockButton format="numbered-list" icon="list-ol" />
                    <BlockButton format="bulleted-list" icon="list-ul" />
                    <BlockButton format="break" icon="horizontal-rule" />
                    <LinkButton />
                </div>
                <div className={styles.input}>
                    <Editable
                        placeholder={placeholder}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        spellCheck={false}
                        autoFocus={false}
                        onKeyDown={event => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event)) {
                                    event.preventDefault();
                                    const mark = HOTKEYS[hotkey];
                                    toggleMark(editor, mark);
                                }
                            }
                        }}
                    />
                </div>
            </Slate>
        </Fragment>
    );
};

const withHtml = editor => {
    const { insertData, isInline, isVoid } = editor;

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element);
    };

    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element);
    };

    editor.insertData = data => {
        const html = data.getData('text/html');

        if (html) {
            const parsed = new DOMParser().parseFromString(html, 'text/html');
            const fragment = deserialize(parsed.body);
            Transforms.insertFragment(editor, fragment);
            return;
        }

        insertData(data);
    };

    return editor;
};

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: n => LIST_TYPES.includes(n.type),
        split: true,
    });

    Transforms.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isBlockActive = (editor, format) => {
    const nodes = Editor.nodes(editor, {
        match: n => n.type === format,
    });
    return !!nodes.next().value;
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'code':
            return (
                <pre>
                    <code {...attributes}>{children}</code>
                </pre>
            );
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
        case 'heading-three':
            return <h3 {...attributes}>{children}</h3>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'link':
            return (
                <a {...attributes} href={element.url}>
                    {children}
                </a>
            );
        default:
            return <p {...attributes}>{children}</p>;
    }
};

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    if (leaf.strikethrough) {
        children = <del>{children}</del>;
    }

    return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <button
            className={`${styles.button} ${
                isBlockActive(editor, format) ? styles.buttonActive : ''
            }`}
            onMouseDown={event => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            <FontAwesomeIcon icon={icon} />
        </button>
    );
};

const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <button
            className={`${styles.button} ${
                isMarkActive(editor, format) ? styles.buttonActive : ''
            }`}
            onMouseDown={event => {
                event.preventDefault();
                toggleMark(editor, format);
            }}
        >
            <FontAwesomeIcon icon={icon} />
        </button>
    );
};

const LinkButton = () => {
    const editor = useSlate();
    return (
        <button
            className={`${styles.button} ${isLinkActive(editor) ? styles.buttonActive : ''}`}
            onMouseDown={event => {
                event.preventDefault();

                if (isLinkActive(editor)) {
                    insertLink(editor);
                    return;
                }

                const url = window.prompt('Enter the URL of the link:');
                if (!url) return;
                insertLink(editor, url);
            }}
        >
            <FontAwesomeIcon icon="link" />
        </button>
    );
};

const withLinks = editor => {
    const { insertData, insertText, isInline } = editor;

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element);
    };

    editor.insertText = text => {
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertText(text);
        }
    };

    editor.insertData = data => {
        const text = data.getData('text/plain');

        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertData(data);
        }
    };

    return editor;
};

const insertLink = (editor, url) => {
    if (editor.selection) {
        wrapLink(editor, url);
    }
};

const isLinkActive = editor => {
    const nodes = Editor.nodes(editor, {
        match: n => n.type === 'link',
    });
    return !!nodes.next().value;
};

const unwrapLink = editor => {
    // console.log('unwrapLink', editor);
    Transforms.unwrapNodes(editor, { match: n => n.type === 'link' });
};

const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
        return;
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};

export default HtmlField;
