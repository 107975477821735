import { Link } from 'react-router-dom';
import React from 'react';

import Position from './Position';
import { subcomponent } from '@helpers';
import styles from './styles.module.scss';

const Image = ({ children, href, alt, src, height }) => {
    const imageClasses = styles.cardImage;
    const styleClasses = { backgroundImage: `url(${src})`, height: height };

    const cardImageTopLeft = subcomponent(children, Position.Top.Left, true);
    const cardImageTopRight = subcomponent(children, Position.Top.Right, true);
    const cardImageBottomLeft = subcomponent(children, Position.Bottom.Left, true);
    const cardImageBottomRight = subcomponent(children, Position.Bottom.Right, true);

    const cardPositionTop =
        cardImageTopLeft || cardImageTopRight ? (
            <span className="card-position-end card-position-top">
                {cardImageTopLeft}
                {cardImageTopRight}
            </span>
        ) : null;

    const cardPositionBottom =
        cardImageBottomLeft || cardImageBottomRight ? (
            <span className="card-position-end card-position-bottom">
                {cardImageBottomLeft}
                {cardImageBottomRight}
            </span>
        ) : null;

    if (href) {
        return (
            <Link to={href} className={imageClasses} alt={alt} style={styleClasses}>
                {cardPositionTop}
                {cardPositionBottom}
            </Link>
        );
    }

    return (
        <div className={imageClasses} alt={alt} style={styleClasses}>
            {cardPositionTop}
            {cardPositionBottom}
        </div>
    );
};

export default Image;
