import React from 'react';
import PropTypes from 'prop-types';

import { subcomponent } from '@helpers';
import Button from '../Button';
import styles from './styles.module.scss';

const Actions = ({ divider = true, center, right, children, onBack }) => {
    return (
        <div
            className={`${styles.cardActions} ${divider ? styles.cardActionsDivider : ''} ${
                center ? styles.cardActionsCenter : ''
            } ${right ? styles.cardActionsRight : ''}`}
        >
            {onBack ? (
                <Action
                    className={styles.cardActionsActionBack}
                    onClick={onBack}
                    inverse
                    secondary
                    small
                >
                    Back
                </Action>
            ) : null}
            {subcomponent(children, Action)}
        </div>
    );
};

const Action = ({ onClick, ...props }) => (
    <div>
        <Button
            onClick={
                onClick
                    ? e => {
                          e.stopPropagation();
                          onClick(e);
                      }
                    : undefined
            }
            {...props}
        />
    </div>
);

Actions.propTypes = {
    divider: PropTypes.bool,
    center: PropTypes.bool,
    right: PropTypes.bool,
    onBack: PropTypes.func,
};

Actions.displayName = 'Actions';
Action.displayName = 'Action';
Actions.Action = Action;

export default Actions;
