import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';

const Position = ({
    top,
    bottom,
    left,
    right,
    text,
    trim,
    emphasis,
    faded,
    bold,
    stars,
    icon,
    badge,
    inverse,
    loading,
}) => {
    return (
        <div
            style={{ backgroundColor: badge }}
            className={`${styles.cardPositionSide} 
             ${(top && right && styles.cardPositionTopRight) || ''} 
             ${(bottom && right && styles.cardPositionBottomRight) || ''} 
             ${(left && top && styles.cardPositionTopLeft) || ''} 
             ${(right && bottom && styles.cardPositionBottomRight) || ''} 
             ${trim ? styles.cardPositionTrim : ''} 
             ${emphasis ? styles.cardPositionEmphasis : ''} 
             ${faded ? styles.cardPositionFaded : ''} 
             ${bold ? styles.cardPositionBold : ''} 
             ${stars ? styles.cardPositionStars : ''} 
             ${badge ? styles.cardPositionBadge : ''} 
             ${inverse ? styles.cardPositionInverse : ''} 
             ${loading ? styles.cardPositionLoading : ''}`}
        >
            {icon && !stars ? <FontAwesomeIcon icon={icon} /> : null}
            {text && !stars ? <span>{text}</span> : null}
            {stars && !text && !icon
                ? Array(stars)
                      .fill()
                      .map((star, index) => <FontAwesomeIcon key={index} icon="star" />)
                : null}
        </div>
    );
};

Position.propTypes = {
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    text: PropTypes.string,
    emphasis: PropTypes.bool,
    faded: PropTypes.bool,
    bold: PropTypes.bool,
    stars: PropTypes.number,
    icon: PropTypes.string,
    trim: PropTypes.bool,
};

const variants = {
    Top: {
        Left: props => <Position {...props} top left />,
        Right: props => <Position {...props} top right />,
    },
    Bottom: {
        Left: props => <Position {...props} bottom left />,
        Right: props => <Position {...props} bottom right />,
    },
};

variants.Top.Left.displayName = 'TopLeft';
variants.Top.Right.displayName = 'TopRight';
variants.Bottom.Left.displayName = 'BottomLeft';
variants.Bottom.Right.displayName = 'BottomRight';

export default variants;
