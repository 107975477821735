import SVG from 'react-inlinesvg';
import { Link } from '@reach/router';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filterInternalChildren, subcomponent } from '@helpers';
import styles from './styles.module.scss';

// New version of card titles
const Title = ({
    bold = true,
    empty,
    image,
    detail,
    href,
    hrefColour = true,
    big = false,
    medium = true,
    small = false,
    badge,
    icon,
    badgeColour,
    children,
}) => {
    const description = subcomponent(children, Description, true);
    const titleImage = subcomponent(children, Image, true);
    let text = null;

    const filteredChildren = filterInternalChildren(children, [Description, Detail, Image]);

    if (href) {
        text = (
            <Link to={href}>
                {empty ? (
                    <span className={styles.cardTitleEmpty} />
                ) : (
                    <Fragment>
                        {!!icon && <FontAwesomeIcon className={styles.cardTitleIcon} icon={icon} />}
                        {filteredChildren}
                    </Fragment>
                )}
            </Link>
        );
    } else {
        text = empty ? (
            <span className={styles.cardTitleEmpty} />
        ) : (
            <Fragment>
                {!!icon && <FontAwesomeIcon className={styles.cardTitleIcon} icon={icon} />}
                {filteredChildren}
            </Fragment>
        );
    }

    return (
        <Fragment>
            <h3
                className={`${styles.cardTitle} ${bold !== false ? styles.cardTitleBold : ''} ${
                    big && !small ? styles.cardTitleBig : ''
                } ${medium && !small && !big ? styles.cardTitleMedium : ''} ${
                    small && !big ? styles.cardTitleSmall : ''
                } ${hrefColour ? '' : styles.cardTitleHrefNoColour} ${
                    badge ? styles.cardTitleBadge : ''
                } ${!!description ? styles.cardTitleHasDescription : ''}`}
            >
                <span
                    style={{
                        backgroundColor: badge !== true && typeof badge === 'string' ? badge : null,
                        color: badgeColour && typeof badgeColour === 'string' ? badgeColour : null,
                    }}
                >
                    {titleImage}
                    {titleImage ? <span> {text} </span> : text}
                </span>
                {subcomponent(children, Detail, true)}
            </h3>
            {description}
        </Fragment>
    );
};

const Description = ({ children, link }) => (
    <div className={styles.cardTitleDescription}>
        {!!link ? (
            link?.includes('http://') || link?.includes('https://') ? (
                <a href={link}>{children}</a>
            ) : (
                <Link to={link}>{children}</Link>
            )
        ) : (
            children
        )}{' '}
    </div>
);

const Detail = ({ children, bold, stars, src, alt, svg, className }) => {
    let cardTitleDetailStars = null,
        cardTitleDetailImage = null;

    if (!children && !stars && !src) return null;

    if (stars) {
        cardTitleDetailStars = Array(stars)
            .fill()
            .map((star, index) => <FontAwesomeIcon key={index} icon="star" />);
    }

    if (src) {
        if (svg) {
            cardTitleDetailImage = <SVG alt={alt} src={src} />;
        } else {
            cardTitleDetailImage = <img alt={alt} src={src} />;
        }
    }

    return (
        <span
            className={`${styles.cardTitleDetail} 
            ${className}
            ${bold ? styles.cardTitleDetailBold : ''}
            ${cardTitleDetailImage ? styles.cardTitleDetailImage : ''} 
            ${cardTitleDetailStars ? styles.cardTitleDetailStars : ''}`}
        >
            {cardTitleDetailStars || cardTitleDetailImage || children}
        </span>
    );
};

const Image = ({ src, svg, height, className, alt }) => {
    if (svg) {
        return <SVG style={{ height }} className={className} alt={alt || ''} src={src} />;
    }

    return <img style={{ height }} className={className} alt={alt || ''} src={src} />;
};

const cardTitleDetailPropTypes = {
    text: PropTypes.string,
    bold: PropTypes.bool,
    image: PropTypes.shape({
        alt: PropTypes.string,
        src: PropTypes.string,
    }),
    stars: PropTypes.number,
};

const cardImagePropTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    href: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Detail.propTypes = cardTitleDetailPropTypes;

Title.propTypes = {
    href: PropTypes.string,
    description: PropTypes.string,
    detail: PropTypes.shape(cardTitleDetailPropTypes),
    bold: PropTypes.bool,
    big: PropTypes.bool,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(cardImagePropTypes)]),
};

Title.displayName = 'Title';
Description.displayName = 'Description';
Detail.displayName = 'Detail';
Image.displayName = 'Image';

Title.Description = Description;
Title.Detail = Detail;
Title.Image = Image;

export default Title;
