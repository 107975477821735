import { Col, Divider, Row } from '@components/Grid';
import Input from '@components/Input';
import React, { Fragment } from 'react';
import Alert from '@components/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';
import Button from '@components/Button';
import Label from '@components/Label';

const InputField = ({
    field: { id, _id, label, hint, optional, type, multiple },
    name,
    error,
    value,
    handleChange,
    setFieldValue,
}) => {
    const $inputs = [];

    // console.log({ name, value });

    return (
        // multiple input
        <Fragment>
            {multiple ? (
                <Fragment>
                    <Label text={label} hint={hint} optional={optional} />
                    <div className={styles.customFieldInput}>
                        <ul>
                            {(value && !!value?.length ? value : [''])?.map((item, index) => (
                                <li key={index}>
                                    <div className={styles.customFieldInputKey}>{`${index +
                                        1}.`}</div>

                                    <Input
                                        ref={ref => ($inputs[index] = ref)}
                                        textarea={type === 'paragraph'}
                                        html={type === 'html'}
                                        value={item}
                                        placeholder={`Item ${index + 1}`}
                                        onChange={
                                            type === 'html'
                                                ? val =>
                                                      setFieldValue(
                                                          name,
                                                          value?.map((item, currIndex) =>
                                                              currIndex !== index ? item : val
                                                          ) ?? [val]
                                                      )
                                                : e => {
                                                      setFieldValue(
                                                          name,
                                                          value?.map((item, currIndex) =>
                                                              currIndex !== index
                                                                  ? item
                                                                  : e.target.value
                                                          ) ?? [e.target.value]
                                                      );
                                                  }
                                        }
                                    />

                                    {value?.length > 1 && (
                                        <div className={styles.customFieldInputDelete}>
                                            <FontAwesomeIcon
                                                icon="times"
                                                onClick={() => {
                                                    setFieldValue(
                                                        name,
                                                        value.filter(
                                                            (item, currIndex) => currIndex !== index
                                                        )
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                        <Divider margin={2} />

                        <Button
                            small
                            text="Add item"
                            icon="plus"
                            onClick={() => {
                                setFieldValue(name, [
                                    ...(value && !!value?.length ? value : ['']),
                                    '',
                                ]);
                            }}
                        />
                    </div>
                </Fragment>
            ) : (
                // singular input
                <Input
                    name={name}
                    label={label}
                    labelHint={hint}
                    optional={optional}
                    value={value}
                    onChange={type === 'html' ? val => setFieldValue(name, val) : handleChange}
                    textarea={type === 'paragraph'}
                    html={type === 'html'}
                />
            )}
            {!!error && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={error} />
                </Fragment>
            )}
        </Fragment>
    );
};

export default InputField;
