import React, { Fragment } from 'react';

import { Col, Divider, Row } from '@components/Grid';
import InputField from '@components/Form/Field/Input';
import RadioField from '@components/Form/Field/Radio';
import CheckboxField from '@components/Form/Field/Checkbox';
import SwitchField from '@components/Form/Field/Switch';
import DropdownField from '@components/Form/Field/Dropdown';
import CollectionField from '@components/Form/Field/Collection';

const Field = data => {
    const {
        field: { _id, type },
    } = data;
    // console.log({ data });

    return (
        <Fragment>
            {(type === 'string' || type === 'paragraph' || type === 'html') && (
                <InputField {...data} />
            )}
            {type === 'radio' && <RadioField {...data} />}
            {type === 'checkbox' && <CheckboxField {...data} />}
            {type === 'switch' && <SwitchField {...data} />}
            {type === 'dropdown' && <DropdownField {...data} />}
            {type === 'collection' && <CollectionField {...data} />}
            <Divider />
        </Fragment>
    );
};

export default Field;
