import React, {Fragment} from 'react';

import { Col, Divider, Row } from '@components/Grid';
import InputField from '@components/CustomFields/CustomField/Input';
import RadioField from '@components/CustomFields/CustomField/Radio';
import CheckboxField from '@components/CustomFields/CustomField/Checkbox';
import SwitchField from '@components/CustomFields/CustomField/Switch';
import DropdownField from '@components/CustomFields/CustomField/Dropdown';
import CollectionField from '@components/CustomFields/CustomField/Collection';

const CustomField = data => {
    const {
        name,
        value,
        error,
        field: { _id, type },
    } = data;

    const props = { ...data, name, value, error };

    return (
        <Fragment>
            {(type === 'string' || type === 'paragraph' || type === 'html') && (
                <InputField {...props} />
            )}
            {type === 'radio' && <RadioField {...props} />}
            {type === 'checkbox' && <CheckboxField {...props} />}
            {type === 'switch' && <SwitchField {...props} />}
            {type === 'dropdown' && <DropdownField {...props} />}
            {type === 'collection' && <CollectionField {...props} />}
            <Divider />
        </Fragment>
    );
};

export default CustomField;
