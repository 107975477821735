import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Text = ({ inline, slash, small, wrap, single, bold, faded, empty, children, status }) => {
    // const Single = ({ children }) => (single ? <span>{children}</span> : children);
    const Empty = ({ children }) =>
        empty ? <span className={styles.textEmptyIcon} /> : <Fragment>{children}</Fragment>;
    const Bold = ({ children }) => (bold ? <b>{children}</b> : <Fragment>{children}</Fragment>);
    const Status = ({ children }) =>
        !!status ? (
            <span className={styles.textStatusWrapper}>
                {status === 'success' ? (
                    <FontAwesomeIcon className={styles.textIcon} icon="check-circle" />
                ) : status === 'danger' || status === 'warning' ? (
                    <FontAwesomeIcon className={styles.textIcon} icon="exclamation-circle" />
                ) : null}
                <span>{children}</span>
            </span>
        ) : (
            <Fragment>{children}</Fragment>
        );
    const Slash = ({ children }) =>
        !!slash ? (
            <span className={styles.textSlashWrapper}>{children}</span>
        ) : (
            <Fragment>{children}</Fragment>
        );

    return (
        <p
            className={`
            ${styles.text} ${small ? styles.textSmall : ''} ${faded ? styles.textFaded : ''} ${
                single ? styles.textSingle : ''
            } ${wrap ? styles.textWrap : ''} 
            ${empty ? styles.textEmpty : ''}
            ${status === 'success' ? styles.textStatusSuccess : ''}
            ${status === 'danger' ? styles.textStatusError : ''}
            ${status === 'warning' ? styles.textStatusWarning : ''} ${
                inline ? styles.textInline : ''
            }`}
        >
            <Empty>
                <Status>
                    <Slash>
                        <Bold>{children}</Bold>
                    </Slash>
                </Status>
            </Empty>
        </p>
    );
};

export default Text;
