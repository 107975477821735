import React, { Fragment, useEffect, useState } from 'react';
import { Col, Divider, Row } from '@components/Grid';
import { getFormFields, handleFormSubmit, renderFormValidation } from '@helpers/form';
import { useSnackbar } from '@components/Snackbar';
import { Formik } from 'formik';
import Field from '@components/Form/Field';
import Button from '@components/Button';
import Card from '@components/Card';
import Alert from '@components/Alert';
import styles from './styles.module.scss';

const Form = ({ form, job }) => {
    const [openSnackbar] = useSnackbar();
    const [completeTimeout, setCompleteTimeout] = useState(null);

    const handleSubmit = async (values, actions) => {
        try {
            await handleFormSubmit(form, values, actions);
            openSnackbar(`${form?.name ?? ''} form was successfully submitted.`);
            actions?.setStatus({ complete: true });
            setCompleteTimeout(
                setTimeout(() => {
                    actions?.setStatus({ complete: false });
                }, 5000)
            );
        } catch (error) {
            console.log(error);
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred submitting this form, please try clearing your cache or try again later.'
                );
        }
    };

    useEffect(() => {
        return () => clearInterval(completeTimeout);
    }, []);

    return (
        <Formik
            initialValues={getFormFields(form)}
            validationSchema={renderFormValidation(form)}
            onSubmit={handleSubmit}
        >
            {props => {
                const { handleSubmit, status } = props;

                return (
                    <Fragment>
                        <Card>
                            <Card.Content>
                                {form?.sections?.map(section => (
                                    <Fragment key={section?._id}>
                                        <Row>
                                            {section?.fields?.map(field => (
                                                <Fragment>
                                                    {field?._id === '5ff5f0c740035e0017433a49' ? (
                                                        <Col xs={12}>
                                                            <Field
                                                                key={field?._id}
                                                                field={field}
                                                                name={`${section._id}.${field?._id}`}
                                                                value={
                                                                    props?.values?.[section?._id]?.[
                                                                        field?._id
                                                                    ]
                                                                }
                                                                error={
                                                                    props?.touched?.[
                                                                        section?._id
                                                                    ]?.[field?._id] &&
                                                                    props?.errors?.[section?._id]?.[
                                                                        field?._id
                                                                    ]
                                                                }
                                                                {...props}
                                                            />
                                                        </Col>
                                                    ) : (
                                                        <Col sm={6} xs={12}>
                                                            <div
                                                                className={
                                                                    field?._id ===
                                                                    '5ff5f0c740035e0017433a48'
                                                                        ? styles.referral
                                                                        : ''
                                                                }
                                                            >
                                                                <Field
                                                                    key={field?._id}
                                                                    field={field}
                                                                    name={`${section._id}.${field?._id}`}
                                                                    value={
                                                                        props?.values?.[
                                                                            section?._id
                                                                        ]?.[field?._id]
                                                                    }
                                                                    error={
                                                                        props?.touched?.[
                                                                            section?._id
                                                                        ]?.[field?._id] &&
                                                                        props?.errors?.[
                                                                            section?._id
                                                                        ]?.[field?._id]
                                                                    }
                                                                    {...props}
                                                                />
                                                            </div>
                                                        </Col>
                                                    )}
                                                </Fragment>
                                            ))}
                                        </Row>
                                    </Fragment>
                                )) ?? null}
                            </Card.Content>
                            <Card.Actions center>
                                <Card.Actions.Action
                                    text={job ? 'Submit' : 'Contact us'}
                                    onClick={handleSubmit}
                                />
                            </Card.Actions>
                        </Card>
                        {status?.complete && (
                            <Fragment>
                                <Divider />
                                <Alert
                                    type="success"
                                    message={`${form?.name ?? ''} form was successfully submitted.`}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                );
            }}
        </Formik>
    );
};

export default Form;
