import React from 'react';
import { Divider } from '@components/Grid';
import Text from '@components/Text';
import CustomField from '@components/CustomFields/CustomField';
import styles from './styles.module.scss';

const CollectionField = ({
    field: { id, _id, label, hint, optional, options, type, multiple, items },
    error,
    value,
    name,
    ...formikProps
}) => {
    return (
        <div className={styles.collectionField}>
            <h2>{label}</h2>
            {!!hint && <Text faded>{hint}</Text>}
            <Divider margin={2} />
            {items?.map((item, index) => (
                <CustomField
                    {...formikProps}
                    field={item}
                    value={value?.[item?.id]}
                    error={error?.[item?.id]}
                    name={`${name}.${item?.id}`}
                />
            ))}
        </div>
    );
};

export default CollectionField;
