import React, { Fragment } from 'react';
import { subcomponent } from '@helpers';

import styles from './styles.module.scss';
import Label from '@components/Label';
import { Divider } from '@components/Grid';
import Alert from '@components/Alert';

const Radios = ({ label, hint, optional, inline, onChange, className = '', children }) => {
    const handleChange = ({ event, id, data }) => {
        onChange && onChange({ event, id, data });
    };

    return (
        <div className={`${styles.radios} ${inline ? styles.radiosInline : ''} ${className}`}>
            {label && <Label text={label} hint={hint} optional={optional} />}
            {subcomponent(children, Radio, false, { onChange: handleChange })}
        </div>
    );
};

const Radio = ({ onChange, checked, label, id, ...data }) => {
    const handleClick = event =>
        onChange && onChange({ event, checked: true, id, data: { label, ...data } });

    // allow consumer to plumb in array of checked ids and if this id matches set to checked automatically for them
    if (Array.isArray(checked)) {
        checked = checked.indexOf(id) > -1;
    }

    // if was not passed a boolean, but instead the checked id, we compare if this checked choice is the same as the id
    if (checked !== true && checked !== false) {
        checked = id === checked;
    }

    return (
        <div
            tabIndex={0}
            className={`${styles.radio} ${checked ? styles.radioChecked : ''}`}
            onClick={handleClick}
            onKeyDown={handleClick ? e => e.key === 'Enter' && handleClick(e) : undefined}
        >
            {label}
        </div>
    );
};

Radios.Radio = Radio;
Radio.displayName = 'Radio';

Radios.defaultProps = {
    inline: false,
};

export default Radios;
