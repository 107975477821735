import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { subcomponent } from '@helpers';
import Label from '@components/Label';

import styles from './styles.module.scss';

const Select = ({
	placeholder,
	label,
	labelHint,
	optional,
	icon,
	value,
	fullWidth = true,
	border = true,
	name,
	onChange,
	children,
	secondary,
	disabled,
}) => {
	const fullWidthClass = fullWidth ? styles.selectFullWidth : '';
	const placeholderClass = !value ? styles.selectPlaceholder : '';
	const noBorderClass = !border ? styles.selectNoBorder : '';
	const themeClass = secondary ? styles.selectSecondary : styles.selectPrimary;
	const svgIcon = icon ? <FontAwesomeIcon icon={icon} /> : <FontAwesomeIcon icon={'caret-down'} />;

	return (
		<Fragment>
			{label ? <Label text={label} hint={labelHint} optional={optional} /> : null}
			<div
				className={`${styles.select} ${themeClass} ${fullWidthClass} ${placeholderClass} ${noBorderClass}`}
			>
				<select name={name} onChange={onChange} value={value || ''} disabled={disabled}>
					{!value && (
						<option value="" disabled>
							{placeholder || 'Please choose an option...'}
						</option>
					)}
					{subcomponent(children, Option)}
				</select>
				{svgIcon}
			</div>
		</Fragment>
	);
};

const Option = ({ value, label }) => <option value={value}>{label}</option>;

Select.Option = Option;
Option.displayName = 'Option';

export default Select;
