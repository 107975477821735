import React, { Fragment } from 'react';
import Radios from '@components/Radios';
import { Divider } from '@components/Grid';
import Alert from '@components/Alert';

const RadioField = ({
    field: { id, _id, label, hint, optional, options, type, multiple },
    error,
    value,
    name,
    handleChange,
    setFieldValue,
}) => {
    return (
        <Fragment>
            <Radios
                inline
                label={label}
                hint={hint}
                optional={optional}
                onChange={({ id }) => {
                    // console.log({ name, id });
                    setFieldValue(name, id);
                }}
            >
                {options?.map(({ label, _id }) => (
                    <Radios.Radio key={_id} label={label} value={_id} checked={value} />
                ))}
            </Radios>
            {!!error && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={error} />
                </Fragment>
            )}
        </Fragment>
    );
};

export default RadioField;
