// merlin form system helpers
import * as Yup from 'yup';
import { makePostRequest } from '@helpers/requests';
import { FORM } from '@helpers/api';

export const getFormFields = form => {
    const sections = {};

    for (let section of form?.sections) {
        sections[section._id] = {};

        for (let field of section?.fields) {
            sections[section._id][field?._id] = undefined;
        }
    }

    return sections;
};

export const renderFormValidation = form => {
    const schema = {};

    Object.keys(form?.sections).forEach(sectionKey => {
        const section = form?.sections[sectionKey];
        const sectionId = section?._id;

        const fieldSchema = {};

        Object.keys(section?.fields).forEach(fieldKey => {
            const field = section?.fields[fieldKey];
            const fieldId = field?._id;

            fieldSchema[fieldId] = renderFormValidationField(field);
        });

        schema[sectionId] = Yup.object().shape(fieldSchema);
    });

    return Yup.object().shape(schema);
};

const renderFormValidationField = ({ type, label, optional, validation }) => {
    let value;

    switch (type) {
        case 'string':
            value = Yup.string();
            if (validation?.type === 'text' && validation?.condition === 'email') {
                value = value.email(validation?.error ?? 'Must be an email address.');
            }
            if (!optional) value = value.required(`${label} is required.`);
            return value;
        case 'paragraph':
        case 'dropdown':
        case 'radio':
            value = Yup.string();
            if (!optional) value = value.required(`${label} is required.`);
            return value;
        case 'checkbox':
            value = Yup.array().of(Yup.string());
            if (!optional) value = value.required(`${label} is required.`);
            return value;
    }

    return Yup.mixed().nullable();
};

export const handleFormSubmit = async (form, values, actions) => {
    try {
        // console.log('handleFormSubmit()', { form, values });
        const postData = {
            formId: form?._id,
            sections: Object.keys(values).map(sectionId => {
                const formSection = form?.sections?.find(({ _id }) => _id === sectionId);

                let sectionData = {
                    _id: sectionId,
                    fields: [],
                };

                for (let fieldId in values[sectionId]) {
                    if (values[sectionId].hasOwnProperty(fieldId)) {
                        const formSectionField = formSection?.fields?.find(
                            ({ _id }) => _id === fieldId
                        );

                        sectionData.fields.push({
                            _id: fieldId,
                            value:
                                formSectionField?.type === 'dropdown' ||
                                formSectionField?.type === 'radio'
                                    ? formSectionField?.options?.find(
                                          ({ _id }) => _id === values[sectionId][fieldId]
                                      )?.label
                                    : values[sectionId][fieldId],
                        });
                    }
                }

                return sectionData;
            }),
        };

        const { data: formData } = await makePostRequest(FORM, postData);
        actions?.resetForm();
    } catch (error) {
        throw error;
    }
};
