import React, { Fragment } from 'react';
import { Divider } from '@components/Grid';
import Alert from '@components/Alert';
import Switch from '@components/Switch';
import Select from '@components/Select';
import Radios from '@components/Radios';

const DropdownField = ({
    field: { id, _id, label, hint, optional, options },
    error,
    value,
    name,
    setFieldValue,
}) => {
    return (
        <Fragment>
            <Select
                label={label}
                hint={hint}
                optional={optional}
                name={name}
                value={value}
                onChange={e => setFieldValue(name, e.target.value)}
            >
                {options?.map(({ label, _id }) => (
                    <Select.Option key={_id} label={label} value={_id} />
                ))}
            </Select>
            {!!error && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={error} />
                </Fragment>
            )}
        </Fragment>
    );
};

export default DropdownField;
