import React, { Fragment } from 'react';
import { Divider } from '@components/Grid';
import Alert from '@components/Alert';
import Switch from '@components/Switch';

const SwitchField = ({
    field: { id, _id, label, hint, optional, options, type, multiple },
    error,
    value,
    name,
    setFieldValue,
}) => {
    return (
        <Fragment>
            <Switch
                label={label}
                hint={hint}
                optional={optional}
                name={name}
                checked={value}
                onChange={(e, checked) => setFieldValue(name, checked)}
            />
            {!!error && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert type="error" message={error} />
                </Fragment>
            )}
        </Fragment>
    );
};

export default SwitchField;
